/**
 * Creates the checkboxes / wrappers for consent capture
 * @param {Object} vendors - response from the API
 * @param {String} targetDiv - div to append the checkboxes to
 */
export default function renderConsentCheckboxes(vendors, targetDiv) {
	const modernizeTargetDiv = document.querySelector('[data-modernize-consent]');

	for (let i = 0; i < vendors.length; i++) {
		const checkboxWrapper = document.createElement('span');
		checkboxWrapper.className = 'checkbox';

		const label = document.createElement('label');
		const input = document.createElement('input');
		input.className = 'consent-capture';
		input.type = 'checkbox';
		input.setAttribute('data-name', vendors[i].vendorDisplayName);
		input.setAttribute('data-key', vendors[i].vendorKey);
		input.setAttribute('checked', 'checked');

		label.appendChild(input);
		label.appendChild(document.createTextNode(vendors[i].vendorDisplayName));

		checkboxWrapper.appendChild(label);

		if (modernizeTargetDiv && vendors[i].vendorDisplayName === 'Modernize') {
			modernizeTargetDiv.innerHTML = checkboxWrapper.outerHTML;
		} else if (targetDiv) {
			targetDiv.appendChild(checkboxWrapper);
		}
	}
}
