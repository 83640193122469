import getEnv from '../url/getEnv';
/**
 * FUTURE Deprecate -> Functionality exists in modalytics
 * Heap Wrapper
 * @param {String} method - method name
 * @param {Mixed} data - string, array or object property of the method
 */
export default function heap(method, data) {
	if (window.Modalytics && !window.Modalytics.privacyPreferencesDetected) {
		const supportedMethods = ['track', 'identify', 'resetIdentity', 'addUserProperties', 'addEventProperties', 'removeEventProperties', 'clearEventProperties'];

		if (supportedMethods.indexOf(method) < 0) {
			console.log('Heap "' + method + '" method is not supported');
			return;
		}

		if ('undefined' !== typeof window.heap) {
			if ('identify' === method || 'track' === method) {
				window.heap[method](data[0], data[1]);
			} else {
				window.heap[method](data);
			}
		}

		if ('production' !== getEnv()) {
			console.log('Heap', method, data);
		}
	}
}
